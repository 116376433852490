import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  sendNotify,
  getOptions,
  fetchApi,
  generateRandom,
  getQuery,
  timeDiff,
  chatTime,
} from "../helper";
const Authenticator = ({ setAuthValue, authValue }) => {
  const [secret, setSecret] = useState("");
  const [qrcode, setQrCode] = useState("");
  const [token, setToken] = useState("");
  const [verificationStatus, setVerificationStatus] = useState(null);

  let { isLogged, userDetails } = useSelector((state) => state.auth);
  //   useEffect(() => {

  //     generateSecret()

  //   }, []);

  //   const handleVerifyToken = () => {
  //     axios.post('/auth/verify-token', {
  //       token: token,
  //       secret: secret
  //     })
  //       .then(response => {
  //         setVerificationStatus(response.data.verified);
  //       })
  //       .catch(error => {
  //         console.error('Error verifying token', error);
  //         setVerificationStatus(false);
  //       });
  //   };

  const generateSecret = async () => {
    try {
      let payload = {
        method: "GET",
        url: `auth/generate-secret/${userDetails._id}`,
      };

      await fetchApi(payload)
        .then((res) => {
          const { secret, qrcode } = res.data;
          setSecret(secret);
          setQrCode(qrcode);
          sendNotify("success", res?.message);
        })
        .catch((error) => {
          sendNotify("error", error?.message);
        });
    } catch (error) {
      console.error("error", error?.message);
    }
  };

  const handleVerifyToken = () => {
    //     axios.post('/auth/verify-token', {
    //       token: token,
    //       secret: secret
    //     })

    axios
      .post("/auth/verify-token", {
        token: token,
        secret: secret,
      })
      .then((response) => {
        setAuthValue({
          f2aEnable: true,
          qrCode: qrcode,
          secretKey: secret,
          verification: response.data.data.verified,
        });
        setVerificationStatus(response.data.data.verified);
        sendNotify("success", response?.data.message);
      })
      .catch((error) => {
        console.error("Error verifying token", error);
      });
  };

  return (
    <div>
      <h1>Google Authenticator Setup</h1>
      <button className="primary-btn" onClick={generateSecret}>
        Generate Secret
      </button>
      <br />
      {qrcode && <img src={qrcode} alt="QR Code" />}
      <p>Scan the QR code with your Google Authenticator app.</p>
      <div className="input-box">
        <input
          type="text"
          placeholder="Enter the token"
          value={token}
          onChange={(e) => setToken(e.target.value)}
        />
      </div>
      <button className="primary-btn" onClick={handleVerifyToken}>
        Verify Token
      </button>

      {verificationStatus !== null && (
        <p>{verificationStatus ? "Token is valid!" : "Token is invalid!"}</p>
      )}
    </div>
  );
};

export default Authenticator;
