import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import Logo from "../../assets/img/sidebar-logo.png";
import routes from "../../helper/routes";

export default function Sidebar() {
  let details = useSelector((state) => state.auth.userDetails);

  const [menuShow, setMenuShow] = useState(null);

  const parentRoutes = [
    { id: 0, name: "Master File Setup", icon: "fa-file-plus" },
    { id: 1, name: "New Incorporation", icon: "fa-newspaper" },
    { id: 2, name: "MLRO", icon: "fa-ticket-alt" },
    { id: 3, name: "Post Incorporation", icon: "fa-file-import" },
    { id: 4, name: "Finance/Filings", icon: "fa-file-invoice-dollar" },
    // { id: 5,  name: "Access Control", icon: "fa-users-cog" },
    { id: 6, name: "Audits & Reporting", icon: "fa-chart-pie" },
    { id: 7, name: "Settings", icon: "fa-cogs" },
  ];

  const toggleMenu = (index) => {
    if (index === menuShow) {
      setMenuShow(null);
    } else {
      setMenuShow(index);
    }
  };

  return (
    <div className={`sidebar`}>
      <div>
        <div className="logo">
          <img src={Logo} alt="North Lark" />
          <p>NorthLark Galactic</p>
        </div>
        <ul className="sidebar-list">
          <li className="sidebar-parent">
            <NavLink to={"/app/home"}>
              <div className="sidebar-menu-text d-flex align-items-center gap-3">
                <i className="fal fa-home"></i> Home
              </div>
            </NavLink>
          </li>
          {parentRoutes.map((pr) => {
            const filteredSubRoutes = routes.filter(
              (r) =>
                r.showInMenu &&
                (r.parentId === pr.id || r.duplicate === pr.id) &&
                r.permissions.includes(details?.role)
            );

            if (filteredSubRoutes.length > 0) {
              return (
                <li key={pr.id} className="sidebar-parent">
                  <a className="sidebar-menu" onClick={() => toggleMenu(pr.id)}>
                    <div className="sidebar-menu-text d-flex align-items-center gap-3">
                      <i className={`fal ${pr.icon}`}></i> {pr.name}
                    </div>
                    <i
                      className={`fal fa-chevron-up ${
                        menuShow === pr.id ? "" : "fa-rotate-180"
                      }`}
                    ></i>
                  </a>

                  <ul
                    className={`child-menu ${menuShow === pr.id ? "show" : ""}`}
                  >
                    {filteredSubRoutes.map((route) => (
                      <li key={route.path}>
                        <NavLink to={route.path}>
                          <div className="sidebar-menu-text d-flex align-items-center gap-3">
                            {/* <i className={`fal ${route.icon}`}></i> */}
                            {route.name}
                          </div>
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
              );
            }

            return null;
          })}
        </ul>
      </div>
      <ul>
        <li>
          <a className="footer-text">
            <i className="fal fa-question-circle"></i> Help and Support
          </a>
        </li>
      </ul>
    </div>
  );
}
