import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/authSlice";
import themeSlice from "./theme/themeSlice";
import countrySlice from "./country/countrySlice";
import incorpSlice from "./incorporation/incorpSlice";
import preIncorpSlice from "./preIncorpRequests/preIncorpSlice";
import postIncorpSlice from "./postIncorpRequests/postIncorpSlice";
import notificationsSlice from "./notifications/notificationsSlice";
import newNotificationsSlice from "./newNotifications/newNotificationsSlice";
import clientSlice from "./client/clientSlice";
import parentClientSlice from "./parentClient/parentClientSlice";
import endUserClientSlice from "./endUserClient/endUserClientSlice";
import entitySlice from "./entity/entitySlice";
import corporateEntitySlice from "./corporateEntity/corporateEntitySlice";
import individualSlice from "./individual/individualSlice";
import introducerClientSlice from "./introducerClient/introducerClientSlice";
import masterCodeSlice from "./masterCode/masterCodeSlice";
import feeScheduleSlice from "./feeSchedule/feeScheduleSlice";
import individualDetailsSlice from "./individualDetails/individualDetailsSlice";
import copEntityDetailsSlice from "./copEntityDetails/copEntityDetailsSlice";
import relationshipManagerSlice from "./relationshipManager/relationshipManagerSlice";
import businessPartnersSlice from "./businessPartners/businessPartnersSlice";
import businessOnboardSlice from "./businessOnboard/businessOnboardSlice";
import organizationStructureSlice from "./organizationStructure/organizationStructureSlice";
import incorpSubmissionSlice from "./incorpSubmission/incorpSubmissionSlice";
import manageRolesSlice from "./manageRoles/manageRolesSlice";
import userSlice from "./user/userSlice";
import onboardingSlice from "./onboarding/onboardingSlice";
import manualStorageSlice from "./manualStorage/manualStorageSlice";
import emailSlice from "./email/emailSlice";
import signatureSlice from "./signature/signatureSlice";
import messagesSlice from "./messages/messagesSlice";
import registerOfChargesSlice from "./registerOfCharges/registerOfChargesSlice";
export const store = configureStore({
  reducer: {
    auth: authSlice,
    theme: themeSlice,
    country: countrySlice,
    incorp: incorpSlice,
    preIncorp: preIncorpSlice,
    postIncorp: postIncorpSlice,
    notification: notificationsSlice,
    newNotification: newNotificationsSlice,
    client: clientSlice,
    parentClient: parentClientSlice,
    endUserClient: endUserClientSlice,
    entity: entitySlice,
    email: emailSlice,
    corporateEntity: corporateEntitySlice,
    individual: individualSlice,
    introducerClient: introducerClientSlice,
    masterCode: masterCodeSlice,
    feeCategory: feeScheduleSlice,
    individualDetails: individualDetailsSlice,
    copEntityDetails: copEntityDetailsSlice,
    relationshipManager: relationshipManagerSlice,
    businessPartners: businessPartnersSlice,
    businessOnboard: businessOnboardSlice,
    organizationStructure: organizationStructureSlice,
    incorpSubmission: incorpSubmissionSlice,
    manageRoles: manageRolesSlice,
    user: userSlice,
    onboarding: onboardingSlice,
    manualStorage: manualStorageSlice,
    signature: signatureSlice,
    messages: messagesSlice,
    registerOfCharges: registerOfChargesSlice,
  },
});
