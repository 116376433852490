import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Checkbox } from "antd";

import SampleDoc from "../assets/pdf/sample.pdf";

import UploadZ from "../components/UploadZ";

import { Each } from "../helper";

const document = [
  {
    url: SampleDoc,
    file: "Certificate of Incumbency",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: true,
  },
  {
    url: SampleDoc,
    file: "M&AA",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
  {
    url: SampleDoc,
    file: "Certificate of Good Standing",
    name: "sample-doc.pdf",
    size: 287392,
    type: "pdf",
    base64: "",
    isApproved: false,
  },
];

export default function ApostilleOfDocuments() {
  const [docs, setDocs] = useState(document);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="page-header">
        <Link to={"/app/active-requests"}>
          <h1 className="d-flex align-items-center gap-2">
            <i className="fal fa-long-arrow-left"></i> Apostille of Documents
          </h1>
        </Link>
        <div className="d-flex gap-3">
          <button className="outline-btn">Check Mailbox</button>
          <button className="primary-btn" disabled={true}>
            Send to Client
          </button>
          <button className="primary-btn">Submit</button>
        </div>
      </div>
      <div className="page-content">
        <div className="custom-form row gy-4 mb-5">
          <div className="col-md-3">
            <label htmlFor="">Entity Code</label>
            <span className="light-badge">38202</span>
          </div>
          <div className="col-md-3">
            <label htmlFor="">Entity Name</label>
            <span className="light-badge">Sample Entity</span>
          </div>
          <div className="col-md-3">
            <label htmlFor="">Client Code</label>
            <span className="light-badge">38202</span>
          </div>
          <div className="col-md-3">
            <label htmlFor="">Client Name</label>
            <span className="light-badge">Sample Client</span>
          </div>
          <div className="col-md-3">
            <label htmlFor="">Documents to be Notarized</label>
            <div className="d-flex gap-2 flex-wrap">
              <span className="light-badge">Certificate of Incumbency</span>
              <span className="light-badge">M&AA</span>
              <span className="light-badge">Certificate of Good Standing</span>
            </div>
          </div>
          <div className="col-md-3">
            <label htmlFor="">Receive as</label>
            <span className="light-badge">Hard copy with physical courier</span>
          </div>
          <div className="col-md-3">
            <label htmlFor="">Address</label>
            <span className="light-badge">
              2nd Floor, Main Street, Road Town, <br /> Tortola, VG1110, BVI
            </span>
          </div>
          <div className="col-md-3">
            <label htmlFor="">Special Notes</label>
            <span className="light-badge">Sample Message</span>
          </div>
        </div>
        <h2 className="form-title mb-4">Notarized Documents</h2>
        <div className="row gy-2 gx-4">
          <Each
            array={docs}
            render={(item, index) => (
              <div className="col-md-3">
                <div>
                  <Checkbox className="custom-checkbox">{item?.file}</Checkbox>
                  <UploadZ file={item} />
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
}
