import React, { useState, useRef } from "react";
import { Table, Tag, Select, Modal, DatePicker } from "antd";
import dayjs from "dayjs";

import FormZ from "../components/FormZ";
import { downloadAsCSV, customPagination } from "../helper";
import TagZ from "../components/TagZ";
import TableSortArrows from "../components/tableSortArrows";

export default function AnnualFilings() {
  const [openHistory, setOpenHistory] = useState(false);

  const columns = [
    {
      title: "Entity Name",
      dataIndex: "Entity Name",
      sorter: (a, b) => a["Entity Name"] - b["Entity Name"],
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      sorter: (a, b) => a.entityCode - b.entityCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Status",
      dataIndex: "entityStatus",
      sorter: (a, b) => a.entityStatus - b.entityStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        // <Tag color="green" className="custom-tag">
        //   Active
        // </Tag>
        <Select
          className="custom-select"
          style={{ width: 150 }}
          placeholder="Select Assignee"
          options={[
            {
              value: "active",
              label: "Active",
            },
            {
              value: "inactive",
              label: "Inactive",
            },
            {
              value: "closing",
              label: "Closing",
            },
            {
              value: "closed",
              label: "Closed",
            },
            {
              value: "prospect",
              label: "Prospect",
            },
            {
              value: "transferringOut",
              label: "Transferring Out",
            },
            {
              value: "transferringIn",
              label: "Transferring In",
            },
            {
              value: "liquidated",
              label: "Liquidated",
            },
            {
              value: "struckOff",
              label: "Struck Off",
            },
            {
              value: "resigned",
              label: "Resigned",
            },
            {
              value: "continuationOut",
              label: "Continuation Out",
            },
            {
              value: "continuationIn",
              label: "Continuation In",
            },
          ]}
        />
      ),
    },
    {
      title: "Incorp Date",
      dataIndex: "incorpDate",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorporation Number",
      dataIndex: "incorporationNumber",
      sorter: (a, b) => a.incorporationNumber - b.incorporationNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { verified }) => (
        <Select
          className="custom-select"
          style={{ width: 150 }}
          placeholder="Select Assignee"
          options={[
            {
              value: "firstHalf",
              label: "First Half",
            },
            {
              value: "secondHalf",
              label: "Second Half",
            },
          ]}
        />
      ),
    },
    {
      title: "Incorp Batch",
      dataIndex: "incorpBatch",
      sorter: (a, b) => a.incorpBatch - b.incorpBatch,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { verified }) => (
        <Select
          className="custom-select"
          style={{ width: 150 }}
          placeholder="Select Assignee"
          options={[
            {
              value: "firstHalf",
              label: "First Half",
            },
            {
              value: "secondHalf",
              label: "Second Half",
            },
          ]}
        />
      ),
    },
    {
      title: "Masterfile Name",
      dataIndex: "clientName",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Code",
      dataIndex: "clientCode",
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Parent Client Name",
      dataIndex: "parentClientName",
      sorter: (a, b) => a.parentClientName - b.parentClientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Parent Client Code",
      dataIndex: "parentClientCode",
      sorter: (a, b) => a.parentClientCode - b.parentClientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Filing type",
      dataIndex: "filingType",
      sorter: (a, b) => a.filingType - b.filingType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Count Of Reminders",
      dataIndex: "countOfReminders",
      sorter: (a, b) => a.countOfReminders - b.countOfReminders,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Deadline Breach",
      dataIndex: "deadlineBreach",
      sorter: (a, b) => a.deadlineBreach - b.deadlineBreach,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { deadlineBreach }) => (
        <TagZ
          status={deadlineBreach == "Y" ? "approved" : "rejected"}
          statusText={deadlineBreach == "Y" ? "Yes" : "No"}
        />
      ),
    },
    {
      title: "Filing Status",
      dataIndex: "filingStatus",
      sorter: (a, b) => a.filingStatus - b.filingStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { filingStatus }) => (
        <TagZ
          status={filingStatus == "Y" ? "approved" : "rejected"}
          statusText={filingStatus == "Y" ? "Yes" : "No"}
        />
      ),
    },
    {
      title: "FSC Notification Status",
      dataIndex: "fscStatus",
      sorter: (a, b) => a.fscStatus - b.fscStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { fscStatus }) => (
        <TagZ
          status={fscStatus == "Y" ? "approved" : "in-progress"}
          statusText={fscStatus == "Y" ? "Submitted" : "Pending"}
        />
      ),
    },
    {
      title: "View FSC Update",
      render: (_, data) => (
        <button className="action-btn icon-btn">
          <i className="fal fa-eye"></i>
        </button>
      ),
    },
  ];

  const columns2 = [
    {
      title: "Entity Name",
      dataIndex: "Entity Name",
      sorter: (a, b) => a["Entity Name"] - b["Entity Name"],
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Code",
      dataIndex: "entityCode",
      sorter: (a, b) => a.entityCode - b.entityCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Entity Status",
      dataIndex: "entityStatus",
      sorter: (a, b) => a.entityStatus - b.entityStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { status }) => (
        // <Tag color="green" className="custom-tag">
        //   Active
        // </Tag>
        <Select
          className="custom-select"
          style={{ width: 150 }}
          placeholder="Select Assignee"
          options={[
            {
              value: "active",
              label: "Active",
            },
            {
              value: "inactive",
              label: "Inactive",
            },
            {
              value: "closing",
              label: "Closing",
            },
            {
              value: "closed",
              label: "Closed",
            },
            {
              value: "prospect",
              label: "Prospect",
            },
            {
              value: "transferringOut",
              label: "Transferring Out",
            },
            {
              value: "transferringIn",
              label: "Transferring In",
            },
            {
              value: "liquidated",
              label: "Liquidated",
            },
            {
              value: "struckOff",
              label: "Struck Off",
            },
            {
              value: "resigned",
              label: "Resigned",
            },
            {
              value: "continuationOut",
              label: "Continuation Out",
            },
            {
              value: "continuationIn",
              label: "Continuation In",
            },
          ]}
        />
      ),
    },
    {
      title: "Incorp Date",
      dataIndex: "incorpDate",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Incorporation Number",
      dataIndex: "incorporationNumber",
      sorter: (a, b) => a.incorporationNumber - b.incorporationNumber,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { verified }) => (
        <Select
          className="custom-select"
          style={{ width: 150 }}
          placeholder="Select Assignee"
          options={[
            {
              value: "firstHalf",
              label: "First Half",
            },
            {
              value: "secondHalf",
              label: "Second Half",
            },
          ]}
        />
      ),
    },
    {
      title: "Incorp Batch",
      dataIndex: "incorpBatch",
      sorter: (a, b) => a.incorpBatch - b.incorpBatch,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { verified }) => (
        <Select
          className="custom-select"
          style={{ width: 150 }}
          placeholder="Select Assignee"
          options={[
            {
              value: "firstHalf",
              label: "First Half",
            },
            {
              value: "secondHalf",
              label: "Second Half",
            },
          ]}
        />
      ),
    },
    {
      title: "Masterfile Name",
      dataIndex: "clientName",
      sorter: (a, b) => a.clientName - b.clientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Masterfile Code",
      dataIndex: "clientCode",
      sorter: (a, b) => a.clientCode - b.clientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Parent Client Name",
      dataIndex: "parentClientName",
      sorter: (a, b) => a.parentClientName - b.parentClientName,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Parent Client Code",
      dataIndex: "parentClientCode",
      sorter: (a, b) => a.parentClientCode - b.parentClientCode,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Filing type",
      dataIndex: "filingType",
      sorter: (a, b) => a.filingType - b.filingType,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Count Of Reminders",
      dataIndex: "countOfReminders",
      sorter: (a, b) => a.countOfReminders - b.countOfReminders,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Deadline Breach",
      dataIndex: "deadlineBreach",
      sorter: (a, b) => a.deadlineBreach - b.deadlineBreach,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { deadlineBreach }) => (
        <TagZ
          status={deadlineBreach == "Y" ? "approved" : "rejected"}
          statusText={deadlineBreach == "Y" ? "Yes" : "No"}
        />
      ),
    },
    {
      title: "Filing Status",
      dataIndex: "filingStatus",
      sorter: (a, b) => a.filingStatus - b.filingStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { filingStatus }) => (
        <TagZ
          status={filingStatus == "Y" ? "approved" : "rejected"}
          statusText={filingStatus == "Y" ? "Yes" : "No"}
        />
      ),
    },
    {
      title: "FSC Notification Status",
      dataIndex: "fscStatus",
      sorter: (a, b) => a.fscStatus - b.fscStatus,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
      render: (_, { fscStatus }) => (
        <TagZ
          status={fscStatus == "Y" ? "approved" : "in-progress"}
          statusText={fscStatus == "Y" ? "Submitted" : "Pending"}
        />
      ),
    },
    {
      title: "View FSC Update",
      render: (_, data) => (
        <button className="action-btn icon-btn">
          <i className="fal fa-eye"></i>
        </button>
      ),
    },
    {
      title: "History",
      render: (_, data) => (
        <button
          className="action-btn icon-btn"
          onClick={() => setOpenHistory(true)}
        >
          <i className="fal fa-eye"></i>
        </button>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      id: "1",
      "Entity Name": "Sample",
      entityType: "Trust",
      entityCode: "9023",
      incorpDate: "09-02-2023",
      rmName: "Sample",
      clientName: "COI",
      clientCode: "839",
      clientType: "Pre-paid",
      parentClientName: "Hari",
      parentClientCode: "632",
      filingType: "Annual Returns",
      countOfReminders: 4,
      deadlineBreach: "Y",
      filingStatus: "N",
      fscStatus: "Y",
    },
    {
      key: "2",
      id: "2",
      "Entity Name": "Sample",
      entityType: "Trust",
      entityCode: "9023",
      incorpDate: "09-02-2023",
      rmName: "Sample",
      clientName: "COI",
      clientCode: "839",
      clientType: "Pre-paid",
      parentClientName: "Hari",
      parentClientCode: "632",
      filingType: "Annual Returns",
      countOfReminders: 2,
      deadlineBreach: "N",
      filingStatus: "N",
      fscStatus: "Y",
    },
    {
      key: "3",
      id: "3",
      "Entity Name": "Sample",
      entityType: "Trust",
      entityCode: "9023",
      incorpDate: "09-02-2023",
      rmName: "Sample",
      clientName: "COI",
      clientCode: "839",
      clientType: "Pre-paid",
      parentClientName: "Hari",
      parentClientCode: "632",
      filingType: "Annual Returns",
      countOfReminders: 1,
      deadlineBreach: "Y",
      filingStatus: "Y",
      fscStatus: "N",
    },
  ];

  const onExportClick = (fileName) => {
    downloadAsCSV(data, fileName);
  };

  const tableColumns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => a.date - b.date,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Analysis Report",
      dataIndex: "analysisReport",
      sorter: (a, b) => a.analysisReport - b.analysisReport,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
    {
      title: "Updated By",
      dataIndex: "updatedBy",
      sorter: (a, b) => a.updatedBy - b.updatedBy,
      sortIcon: ({ sortOrder }) => <TableSortArrows sorted={sortOrder} />,
    },
  ];

  const tableData = [
    {
      id: "001",
      date: "12/04/2024 - 03:24PM",
      raf: "",
      analysisReport: "Sample",
      updatedBy: "Guru",
    },
    {
      id: "002",
      date: "11/04/2024 - 02:53PM",
      raf: "",
      analysisReport: "Sample",
      updatedBy: "Guru",
    },
    {
      id: "003",
      date: "10/04/2024 - 10:01AM",
      raf: "",
      analysisReport: "Sample",
      updatedBy: "Guru",
    },
    {
      id: "004",
      date: "10/04/2024 - 09:24AM",
      raf: "",
      analysisReport: "Sample",
      updatedBy: "Guru",
    },
    {
      id: "005",
      date: "05/04/2024 - 11:12AM",
      raf: "",
      analysisReport: "Sample",
      updatedBy: "Guru",
    },
  ];

  return (
    <div>
      <div className="page-header">
        <h1>Filing</h1>
      </div>
      <div className="page-content">
        <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-3">
          <h6 className="primary-title mb-0">Annual Return Filing</h6>
          <button className="primary-btn" onClick={onExportClick}>
            <i className="fal fa-file-download"></i> Export
          </button>
        </div>
        <Table
          className="custom-table mb-3"
          columns={columns}
          dataSource={data}
          scroll={{ x: 2400 }}
          pagination={{
            className: "custom-pagination",
            itemRender: customPagination,
          }}
        />

        <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-3">
          <h6 className="primary-title mb-0">Boss Filing Summary</h6>
          <button className="primary-btn" onClick={onExportClick}>
            <i className="fal fa-file-download"></i> Export
          </button>
        </div>
        <Table
          className="custom-table mb-3"
          columns={columns}
          dataSource={data}
          scroll={{ x: 2400 }}
          pagination={{
            className: "custom-pagination",
            itemRender: customPagination,
          }}
        />

        <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center mb-3">
          <h6 className="primary-title mb-0">ROD Filing</h6>
          <button className="primary-btn" onClick={onExportClick}>
            <i className="fal fa-file-download"></i> Export
          </button>
        </div>
        <Table
          className="custom-table"
          columns={columns2}
          dataSource={data}
          scroll={{ x: 2500 }}
          pagination={{
            className: "custom-pagination",
            itemRender: customPagination,
          }}
        />
        <Modal
          title={"History"}
          className="custom-modal"
          open={openHistory}
          width={800}
          onOk={() => setOpenHistory(false)}
          okText="Submit"
          onCancel={() => setOpenHistory(false)}
        >
          <div>
            <Table
              className="custom-table"
              columns={tableColumns}
              dataSource={tableData}
              pagination={false}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
}
