import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import socketIOClient from "socket.io-client";

import FormZ from "../../../components/FormZ";

import {
  sendNotify,
  getOptions,
  convertQueryParams,
  fetchApi,
} from "../../../helper";

const socket = socketIOClient(
  process.env.REACT_APP_API_URL.split("/api", 1)[0]
);

export default function Client({
  clientClicked,
  setClientClicked,
  setselectedPage,
  selectedDate,
}) {
  const { data, isLoading } = useSelector((state) => state.country);
  const [formData, setFormData] = useState({});
  const [feeCategoryArray, setFeeCategoryArray] = useState([]);
  const [feeSubCategoryArray, setFeeSubCategoryArray] = useState([]);

  const formRef = useRef();
  let selectedObj = selectedDate ? selectedDate : {};
  useEffect(() => {
    if (!selectedDate) {
      updateMastercode();
      configureSocket();
      handleRefresh();
    }
  }, []);
  useEffect(() => {
    selectedDateOnSet();
  }, [selectedObj]);

  const selectedDateOnSet = async () => {
    if (selectedObj._id) {
      setFormData(selectedObj);
    }
  };

  const configureSocket = () => {
    // socket.on("connection", () => {
    //   console.log(" Connected backend");
    // });
    socket.on("counter", (counter) => {
      if (counter.update === true) {
        updateMastercode();
      }
    });
    return () => {
      disconnectSocket();
    };
  };

  const disconnectSocket = () => {
    socket.off("counter");
  };
  const handleRefresh = async () => {
    let payload = {
      method: "GET",
      url: `/feeCategory?_start=0&_limit=1000`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res.data.rows;
    });
    setFeeCategoryArray(data);
  };
  const updateMastercode = async () => {
    let payload = {
      method: "GET",
      url: `/admin/masterCode?name=client`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res.data.data;
    });
    let originalString = data.seq;
    let numericPart = originalString.match(/\d+$/)[0];
    let incrementedNumeric = parseInt(numericPart, 10) + 1;
    let newNumericPart = String(incrementedNumeric).padStart(
      numericPart.length,
      "0"
    );
    let newString = originalString.replace(/\d+$/, newNumericPart);
    setFormData({ code: newString, type: "introducer", status: "prospect" });
  };
  let list = feeCategoryArray.map((d) => {
    let obj = {
      value: d?._id,
      label: d?.name,
    };
    return obj;
  });

  useEffect(() => {
    if (clientClicked) {
      onSubmit(selectedDate?._id);
    }
  }, [clientClicked]);

  const onSubmit = (selectedCatId) => {
    const valid = formRef.current.validForm();
    if (!valid) {
      sendNotify("error", "Some fields are missing!");
      return;
    }
    const options = formRef.current.getPayload();
    options.masterType = "client";
    options.nic = "CL";
    const payload = {
      method: selectedCatId ? "PUT" : "POST",
      url: selectedCatId ? `/client/update/${selectedCatId}` : `/client`,
      data: options,
    };

    fetchApi(payload)
      .then((res) => {
        sendNotify("success", res?.message);
        setClientClicked(false);
        if (!selectedCatId) {
          setTimeout(() => {
            window.location.href = `/app/master-search?action=client`;
          }, 1000);
        }
      })
      .catch((error) => {
        sendNotify("error", error?.message);
        setClientClicked(false);
      });
  };

  if (setselectedPage) {
    setselectedPage("client");
  }

  let formSchema = [
    {
      name: "code",
      value: "",
      type: "text",
      label: "Masterfile Code",
      placeholder: "Enter masterfile code",
      disabled: true,
    },
    {
      name: "name",
      value: "",
      type: "text",
      label: "Masterfile Name",
      placeholder: "Enter masterfile name",
      required: true,
    },
    {
      name: "type",
      value: "introducer",
      type: "select",
      label: "Client Type",
      placeholder: "Enter client type",
      options: [
        {
          value: "introducer",
          label: "Introducer Client",
        },
        {
          value: "endUser",
          label: "End User Client",
        },
      ],
      disabled: true,
      required: true,
    },
    {
      name: "pCode",
      value: "",
      type: "text",
      label: "Parent Client Code",
      placeholder: "Enter parent client code",
    },
    {
      name: "status",
      value: "",
      type: "select",
      label: "Client Status",
      placeholder: "Select Client status",
      options: [
        {
          value: "active",
          label: "Active",
        },
        {
          value: "inactive",
          label: "Inactive",
        },
        {
          value: "closing",
          label: "Closing",
        },
        {
          value: "closed",
          label: "Closed",
        },
        {
          value: "transferringOut",
          label: "Transferring Out",
        },
        {
          value: "prospect",
          label: "Prospect",
        },
        {
          value: "transferringIn",
          label: "Transferring In",
        },
        {
          value: "liquidated",
          label: "Liquidated",
        },
        {
          value: "struckOff",
          label: "Struck Off",
        },
        {
          value: "resigned",
          label: "Resigned",
        },
        {
          value: "continuationOut",
          label: "Continuation Out",
        },
        {
          value: "continuationIn",
          label: "Continuation In",
        },
      ],
      disabled: selectedDate ? false : true,
    },

    {
      name: "relationshipManager",
      value: "",
      type: "text",
      label: "Relationship Manager",
      placeholder: "Enter relationship manager name",
    },
    {
      name: "administrator",
      value: "",
      type: "text",
      label: "Administrator",
      placeholder: "Enter administrator name",
    },
    {
      name: "adminManager",
      value: "",
      type: "text",
      label: "Admin Manager",
      placeholder: "Enter admin manager name",
    },
    {
      name: "jurisdiction",
      value: "",
      type: "select",
      label: "Jurisdiction",
      placeholder: "Select jurisdiction",
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
      required: true,
    },
    {
      name: "feeCat",
      value: "",
      type: "select",
      label: "Fee Schedule Name",
      placeholder: "Select Fee Schedule Name",
      options: getOptions(list),
      loading: isLoading,
      //disabled: true,
    },
    {
      name: "feeType",
      value: "",
      type: "select",
      label: "Fee Type",
      placeholder: "Select fee type",
      // disabled: true,
      options: [
        {
          value: "Pre-Paid",
          label: "Pre-paid",
        },
        {
          value: "Post-Paid",
          label: "Post-paid",
        },
      ],
    },
    {
      name: "subCat",
      value: null,
      type: "select",
      label: "Fee Sub Category",
      placeholder: "Select fee sub-category",
      mode: "multiple",
      //   disabled: true,
      inputClass: "ptb-1",
      allowClear: true,
      //options: []
    },
    // {
    //   name: "name",
    //   value: "",
    //   type: "text",
    //   label: "Name",
    //   placeholder: "Enter name",
    // },
    {
      name: "email",
      value: "",
      type: "email",
      label: "Email",
      placeholder: "Enter email address",
      required: true,
    },
    {
      name: "contactNo",
      value: "",
      type: "number",
      label: "Contact Number",
      placeholder: "Enter contact number",
      customClass: "hide-arrows",
      required: true,
    },
    {
      name: "createdAt",
      value: "",
      type: "date",
      label: "Date of Creation",
      placeholder: "Select date",
    },
  ];

  const handleOnChange = async (value) => {
    let data = { ...selectedObj, ...value };

    let { feeCat, feeType } = value;
    if (feeCat && feeType) {
      let params = { catId: feeCat, type: feeType };
      let query = convertQueryParams(params);
      let payload = {
        method: "GET",
        url: `/feeCategory/sub/?${query}`,
      };
      let data = await fetchApi(payload).then((res) => {
        return res.data.data;
      });

      let sublist = data
        ? data?.map((d) => {
            let obj = {
              value: d?._id,
              label: d?.catType,
            };
            return obj;
          })
        : [];
      setFeeSubCategoryArray(sublist);
    }
    setFormData(data);
  };

  return (
    <div className="custom-form">
      <h2 className="sub-title mb-4">
        {`Master File ${!selectedDate ? "Creation" : "Update"}: Client`}
      </h2>
      <FormZ
        formSchema={formSchema}
        ref={formRef}
        onChange={handleOnChange}
        formData={formData}
        formOptions={{ subCat: feeSubCategoryArray }}
        formClass="row col-md-9 mb-3 mb-md-4 gy-3 gx-4"
        childClass="col-md-4 mb-0"
        labelClass="mb-1"
        inputClass="w-100"
      />

      {/* <div className="d-flex gap-3">
                <button className='primary-btn'>Analyze</button>
                <button className='outline-btn'>Web Search</button>
            </div> */}
    </div>
  );
}
