import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "antd";

import UploadZ from "../components/UploadZ";
import { sendNotify } from "../helper";

const { confirm } = Modal;

export default function CertificateOfGoodStanding() {
  const [completedStep, setCompletedStep] = useState(0);
  const [uploadError, setUploadError] = useState(false);
  const [certificate, setCertificate] = useState(null);

  const getCertificate = (file) => {
    setUploadError(false);
    setCertificate(file);
  };

  const sendToClient = () => {
    if (certificate) {
      setUploadError(false);
      confirm({
        title: <h5>Send to Client</h5>,
        icon: (
          <div className="modal-icon">
            <i className="fas fa-question-circle color-primary"></i>
          </div>
        ),
        content: (
          <p>Are you sure you want to send this document to the client?</p>
        ),
        open: false,
        className: "custom-confirm",
        centered: true,
        closable: true,
        okText: "Send",
        onOk() {
          console.log("Sended to Client");
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    } else {
      setUploadError(true);
      sendNotify("error", "Upload Certificate of Good Standing");
    }
  };

  return (
    <div>
      <div className="page-header">
        <Link to={"/app/active-requests"}>
          <h1 className="d-flex align-items-center gap-2">
            <i className="fal fa-long-arrow-left"></i> Certificate of Good
            Standing
          </h1>
        </Link>
        <Link to={"/app/entity-overview/list"} className="outline-btn">
          Refer Entity Overview
        </Link>
      </div>
      <div className="page-content py-0">
        <div className="task-row row align-items-center py-5">
          <div className="col-md-4">
            <h6>1. Request a Certificate of Good Standing</h6>
          </div>
          <div className="col-md-4">
            <div className="d-flex gap-2">
              <button
                className="primary-btn"
                disabled={completedStep >= 1}
                onClick={() => setCompletedStep(1)}
              >
                Request COGS
              </button>
              {completedStep >= 1 && (
                <div className="completed-icon">
                  <i class="fas fa-check-circle"></i>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="task-row row align-items-center py-5">
          <div className="col-md-4">
            <h6>2. Check your mailbox for a response</h6>
          </div>
          <div className="col-md-4">
            <button
              className="primary-btn"
              disabled={completedStep == 0}
              onClick={() => setCompletedStep(2)}
            >
              Check Mailbox
            </button>
          </div>
        </div>
        <div className="task-row row py-5">
          <div className="col-md-4">
            <h6>3. Upload Certificate of Good Standing</h6>
            <div className="w-75 mt-4 ms-4">
              <UploadZ customError={uploadError} />
            </div>
          </div>
          <div className="col-md-4">
            <button
              className="primary-btn"
              disabled={completedStep < 2}
              onClick={sendToClient}
            >
              Send to Client
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
