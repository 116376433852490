import { useState, useEffect, useRef } from "react";
import { Table, Select, Switch, Checkbox, Modal, TreeSelect } from "antd";

import { useSelector, useDispatch } from "react-redux";

import { getOrganizationStructure } from "../../../store/organizationStructure/organizationStructureSlice";
import { getIndividualDetails } from "../../../store/individualDetails/individualDetailsSlice";

import FormZ from "../../../components/FormZ";

import {
  sendNotify,
  getOptions,
  fetchApi,
  convertQueryParams,
  getQuery,
} from "../../../helper";

export default function IndividualForm(props) {
  const { data, isLoading } = useSelector((state) => state.country);
  const individualDetails = useSelector((state) => state.individualDetails);
  const organizationStructure = useSelector(
    (state) => state.organizationStructure
  );
  const individualFormRef = useRef([]);
  let query = getQuery();
  const dispatch = useDispatch();
  const [sanction, setSanction] = useState("Y");
  const [pep, setPep] = useState("Y");
  const [pepText, setPepText] = useState("");
  const [sanctionText, setSanctionText] = useState("");

  const [checkedList, setCheckedList] = useState({
    shareholders: false,
    ubo: false,
    nominee: false,
  });

  const [historyShow, setHistoryShow] = useState([]);
  const [directorChecked, setDirectorChecked] = useState(false);
  const [reserveChecked, setReserveChecked] = useState(false);
  const [shareholderChecked, setShareholderChecked] = useState(false);
  const [uboChecked, setUboChecked] = useState(false);
  const [nomineeChecked, setNomineeChecked] = useState(false);
  const [openindividual, setOpenindividual] = useState(false);
  const [individualShow, setIndividualShow] = useState(false);
  const [objData, setObjData] = useState({});
  const [formData, setFormData] = useState({});
  const [individualArray, setIndividualArray] = useState([]);
  const [individualData, setIndividualData] = useState([]);

  const [selectedId, setSelectedId] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 5 });

  let { index, name, addNewCol, removeNewCol, entityData, id } = props;

  const handleChange = (value) => {
    setSanction(value);
  };

  useEffect(() => {
    if (id || query.id) {
      let params = { enId: id ? id : query.id };
      dispatch(getIndividualDetails(params));
      dispatch(getOrganizationStructure(params));
    }
  }, [id]);

  useEffect(() => {
    if (id || query.id) {
      neworganizationStructure();
    }
  }, [individualDetails, organizationStructure]);

  const neworganizationStructure = async () => {
    if (organizationStructure?.data?.rows?.length) {
    } else {
      let obj = {
        master: true,
        enId: id ? id : query.id,
      };
      let payload = {
        method: "POST",
        url: `/organizationStructure`,
        data: obj,
      };

      fetchApi(payload)
        .then((res) => {
          if (res.status == 201 && res?.data?.length) {
            dispatch(getOrganizationStructure(queryParams));
          }
        })
        .catch((error) => {});
    }
  };

  const handleChangePep = (value) => {
    setPep(value);
  };

  const [individualFormSchema, setIndividualFormSchema] = useState([
    {
      name: "code",
      value: "",
      type: "select",
      label: "Masterfile Code",
      placeholder: "Enter masterfile code",
      //  options: [],
      loading: isLoading,
      showSearch: true,
    },
    {
      name: "title",
      value: "",
      type: "select",
      label: "Salutation",
      placeholder: "Enter salutation",
      disabled: true,
      options: [
        {
          value: "mr",
          label: "Mr",
        },
        {
          value: "mrs",
          label: "Mrs",
        },
        {
          value: "miss",
          label: "Miss",
        },
        {
          value: "ms",
          label: "Ms",
        },
        {
          value: "dr",
          label: "Dr",
        },
        {
          value: "rev",
          label: "Rev",
        },
      ],
    },
    {
      name: "firstName",
      value: "",
      type: "text",
      label: "First Name",
      disabled: true,
      placeholder: "Enter first name",
    },
    {
      name: "middleName",
      value: "",
      type: "text",
      label: "Middle Name",
      placeholder: "Enter Middle name",
      disabled: true,
    },
    {
      name: "lastName",
      value: "",
      type: "text",
      label: "Last Name",
      placeholder: "Enter last name",
      disabled: true,
    },
    {
      name: "DOB",
      value: "", //
      type: "date",
      label: "Date of Birth",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
      disabled: true,
    },
    {
      name: "COB",
      value: "",
      type: "select",
      label: "Country of Birth",
      placeholder: "Select country of birth",
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
      disabled: true,
    },
    {
      name: "nationality",
      value: "",
      type: "select",
      label: "Nationality",
      placeholder: "Select nationality",
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
      disabled: true,
    },
    {
      name: "taxInfo",
      value: "",
      type: "text",
      label: "Tax Info Number",
      placeholder: "Enter tax info number",
      disabled: true,
    },
    {
      name: "passportNo",
      value: "",
      type: "text",
      label: "Passport Number",
      placeholder: "Enter passport number",
      disabled: true,
    },
    {
      name: "passportExp",
      value: "", //dayjs("12-12-2028", "DD/MM/YYYY")
      type: "date",
      label: "Expiry Date",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
      disabled: true,
    },
    {
      name: "riskRating",
      value: "",
      type: "select",
      label: "Risk Level",
      placeholder: "Select risk level",
      disabled: true,
      options: [
        {
          value: "low",
          label: "Low",
        },
        {
          value: "medium",
          label: "Medium",
        },
        {
          value: "high",
          label: "High",
        },
      ],
    },
    {
      // name: "pep",
      // value: "Y",
      // type: "select",
      // label: "PEP",
      // placeholder: "Enter pep",
      // options: [
      //   {
      //     value: "Y",
      //     label: "Yes",
      //   },
      //   {
      //     value: "N",
      //     label: "No",
      //   },
      // ],
      customClass: pep == "Y" ? "col-md-3" : "col-lg-1",
      label: "PEP",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${pep == "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={pep}
            disabled={true}
            onChange={handleChangePep}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {pep == "Y" && (
            <input
              type="text"
              disabled={true}
              value={pepText}
              className="w-100"
              placeholder="Enter pep"
            />
          )}
        </div>
      ),
    },
    {
      customClass: sanction == "Y" ? "col-md-3" : "col-lg-1",
      label: "Sanction",
      customElement: (
        <div className="d-flex gap-2">
          <Select
            className={`custom-select ${sanction == "Y" ? "w-50" : "w-100"}`}
            placeholder="Select"
            value={sanction}
            disabled={true}
            onChange={handleChange}
            options={[
              {
                value: "Y",
                label: "Yes",
              },
              {
                value: "N",
                label: "No",
              },
            ]}
          />
          {sanction == "Y" && (
            <input
              type="text"
              className="w-100"
              disabled={true}
              value={sanctionText}
              placeholder="Enter sanction"
            />
          )}
        </div>
      ),
    },
    {
      name: "contactDetails",
      value: "",
      type: "number",
      label: "Contact Details",
      placeholder: "Enter contact details",
      disabled: true,
    },
    {
      name: "email",
      value: "",
      type: "email",
      label: "Email Address",
      placeholder: "Enter email address",
      disabled: true,
    },
  ]);

  const onChangeHandler = (setIdentifierState, event) => {
    setIdentifierState(event.target.value);
  };

  const individualcolumns = [
    {
      title: "Individual Code",
      dataIndex: "individualCode",
      sorter: (a, b) => a.individualName - b.individualName,
    },
    {
      title: "Individual Name",
      dataIndex: "individualName",
      sorter: (a, b) => a.noShares - b.noShares,
    },
    {
      title: "Select Type",
      dataIndex: "shareType",
      sorter: (a, b) => a.shareType - b.shareType,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      format: "DD/MM/YYYY",
      sorter: (a, b) => a.incorpDate - b.incorpDate,
    },
    {
      title: "Delete / Update",
      render: (_, { _id }, index) => (
        <div className="d-flex gap-2 align-items-center justify-content-end">
          <button className="action-btn delete-btn">
            <i className="far fa-trash-alt"></i>
          </button>
          <button
            className="action-btn"
            onClick={() => individualEditModalOnClick(_id, index)}
          >
            <i className="far fa-pencil"></i>
          </button>
        </div>
      ),
    },
  ];

  let directorFormSchema = [
    {
      name: "dateAppointment",
      value: "",
      type: "date",
      label: "Date of Appointment",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
    {
      name: "dateResignation",
      value: "",
      type: "date",
      label: "Date of Resignation",
      placeholder: "Enter Date (DD/MM/YYYY)",
      format: "DD/MM/YYYY",
    },
  ];

  let shareholderFormSchema = [
    {
      name: "sharesHeld",
      value: "",
      type: "number",
      label: "No. of Shares Held",
      placeholder: "Enter no. of shares held",
    },
  ];

  let uboFormSchema = [
    {
      name: "totalSharesHeld",
      value: "",
      type: "number",
      label: "Total Shares Held",
      placeholder: "Enter total shares held",
    },
    {
      name: "ownershipPercentage",
      value: "",
      type: "text",
      label: "Ownership Percentage",
      placeholder: "Enter ownership percentage",
      disabled: true,
    },
  ];

  let nomineeShareholder = [
    {
      name: "nomineeSharesHeld",
      value: "",
      type: "number",
      label: "No. of Shares Held",
      placeholder: "Enter no. of shares held",
    },
    {
      name: "nomineeAgreementDate",
      value: "",
      type: "date",
      label: "Nominee Agreement Date",
      placeholder: "Enter nominee agreement Date",
    },
  ];

  function removeArrItem(values, arr) {
    for (let i = 0; i < arr.length; i++) {
      for (let index = 0; index < values.length; index++) {
        if (arr[i]?.name === values[index]?.name) {
          arr.splice(i, 1);
        }
      }
    }
  }

  const addNewChange = (values, checkStatus, name) => {
    // let data = { ...checkArray }
    // data.value = [...data?.value ?? []];
    // if (checkStatus) {
    //   data.value.push(name);
    // } else {
    //   const index = data.value.indexOf(name);
    //   if (index !== -1) {
    //     data.value.splice(index, 1);
    //   }
    // }

    // setCheckArray(data);
    setUboChecked(checkStatus);
    updateChecked("ubo", checkStatus);
    let arr = [...individualFormSchema];
    if (checkStatus) {
      arr.push(...values);
    } else {
      removeArrItem(values, arr);
    }
    setIndividualFormSchema(arr);
  };

  const addNewRow = (values, checkStatus, name) => {
    updateChecked(name, checkStatus);
    let arr = [...individualFormSchema];

    if (checkStatus) {
      removeArrItem(values, arr);
    }

    if (name == "directors") {
      if (checkStatus) {
        arr.push(...values);
        setDirectorChecked(true);
        setReserveChecked(false);
      } else {
        removeArrItem(values, arr);
        setDirectorChecked(false);
      }
    }
    if (name == "reserve") {
      if (checkStatus) {
        arr.push(...values);
        setReserveChecked(true);
        setDirectorChecked(false);
      } else {
        removeArrItem(values, arr);
        setReserveChecked(false);
      }
    }
    setIndividualFormSchema(arr);
  };

  const addNewCustom = (values, name, checkStatus) => {
    let arr = [...individualFormSchema];

    updateChecked(name, checkStatus);

    if (name == "shareholders") {
      if (checkStatus) {
        arr.push(...values);
        setShareholderChecked(true);
        setNomineeChecked(false);
        removeArrItem(nomineeShareholder, arr);
      } else {
        removeArrItem(values, arr);
        setShareholderChecked(false);
      }
    }

    if (name == "nominee") {
      if (checkStatus) {
        arr.push(...values);
        setNomineeChecked(true);
        setShareholderChecked(false);
        removeArrItem(shareholderFormSchema, arr);
      } else {
        removeArrItem(values, arr);
        setNomineeChecked(false);
      }
    }
    setIndividualFormSchema(arr);
  };

  function updateChecked(key, checked) {
    let obj = { ...checkedList };
    if (key == "nominee" && checked) {
      obj["shareholders"] = false;
    }
    if (key == "shareholders" && checked) {
      obj["nominee"] = false;
    }
    obj[key] = checked;
    setCheckedList(obj);
  }

  const toggleHistory = (index) => {
    let arr = [...historyShow];
    let item = arr.findIndex((hs) => hs === index);
    if (item !== -1) {
      arr.splice(item, 1);
    } else {
      arr.push(index);
    }
    setHistoryShow(arr);
  };

  const individualOnChange = async (value) => {
    //setFormData(value);
    let data = { ...objData };
    let { totalSharesHeld, code } = value;
    let clientData = individualData.filter((e) => e._id || e.code == code);
    clientData = clientData.map((item) => {
      let newItem = { ...item };
      newItem.pep = newItem.details.pep;
      newItem.sanction = newItem.details.sanction;
      newItem.pepText = newItem.details.pepText;
      newItem.sanctionText = newItem.details.sanctionText;
      newItem.riskRating = newItem.details.riskRating;
      newItem.address = { ...newItem.details.address };
      //  delete newItem.details;
      return newItem;
    });
    if (code && !totalSharesHeld) {
      setFormData(
        value?.title
          ? { ...value, _id: clientData[0]?._id ?? "" }
          : clientData[0] ?? {}
      );
    }

    if (data.totalSharesHeld !== totalSharesHeld || !data.totalSharesHeld) {
      if (totalSharesHeld) {
        let percentage =
          (Number(totalSharesHeld) / Number(entityData?.authorisedShares)) *
          100;
        value.ownershipPercentage = `${percentage} %`;
        value._id = clientData[0]?._id ?? "";
        setFormData(value);
      }
    }
    setObjData(value);
  };

  const saveOnClick = () => {
    saveData(selectedId);
  };

  const saveData = async () => {
    const prepareData = () => ({
      invId: formData._id,
      enId: entityData._id,
      resignation: formData.dateResignation,
      appointment: formData.dateAppointment,
      noOfShares: formData.sharesHeld || formData.nomineeSharesHeld,
      totalShares: formData.totalSharesHeld,
      ownershipPer: formData.ownershipPercentage,
      agreementDate: formData.nomineeAgreementDate,
      checkedList: checkedList,
    });

    const handleCheckedList = () => {
      const organizationArray = organizationStructure.data.rows.filter(
        (a) => a.pid == null
      );
      const fullName = `${formData.title} ${formData.firstName} ${
        formData.middleName || ""
      } ${formData.lastName}`
        .replace(/\s+/g, " ")
        .trim();

      const createObj = (role, shares, percentage = null) => {
        const roleData = organizationArray.find((i) => i.role === role);
        return roleData
          ? {
              name: fullName,
              pid: roleData._id,
              color: roleData.color,
              role: "Individual",
              pType: role,
              shares,
              percentage,
            }
          : null;
      };

      const objArray = [];
      if (checkedList.directors || checkedList.reserve) {
        objArray.push({
          ...createObj("Director"),
          reserveDirector: checkedList.reserve,
        });
      }
      if (checkedList.shareholders || checkedList.nominee) {
        const sharePercentage = `${Math.round(
          (Number(formData.sharesHeld) / Number(entityData?.authorisedShares)) *
            100
        )}%`;
        objArray.push(
          createObj("Shareholder", formData.sharesHeld, sharePercentage)
        );
      }
      if (checkedList.ubo) {
        objArray.push(
          createObj(
            "UBO",
            formData.totalSharesHeld,
            formData.ownershipPercentage
          )
        );
      }

      return objArray.filter(Boolean); // Filter out any null values
    };

    const handleApiPost = async (url, data) => {
      try {
        const response = await fetchApi({ method: "POST", url, data });
        return response;
      } catch (error) {
        sendNotify("error", error?.message);
        throw error;
      }
    };

    const processRows = async (objArray) => {
      for (const obj of objArray) {
        await handleApiPost(`/organizationStructure`, obj);
      }
    };

    if (formData._id) {
      const data = prepareData();
      if (Object.values(checkedList).some(Boolean)) {
        try {
          const response = await handleApiPost(`/individualDetails`, data);
          sendNotify("success", response?.message);

          if (organizationStructure?.data?.rows?.length) {
            const objArray = handleCheckedList();
            await processRows(objArray);
          } else {
            const params = { enId: id || query.id };
            dispatch(getIndividualDetails(params));
            dispatch(getOrganizationStructure(params));
          }
        } catch (error) {
          sendNotify("error", error?.message);
        }

        closeModalOnClick();
        const params = { enId: id || query.id };
        dispatch(getIndividualDetails(params));
      } else {
        sendNotify("error", "Please enter at least one name");
      }
    } else {
      sendNotify("error", "Please enter at least one name");
    }
  };

  const individualDropDownOnClick = async (value) => {
    let params = {};
    params.isAll = true;
    params.masterType = "individual";
    params.code = value;
    let query = convertQueryParams(params);
    let payload = {
      method: "GET",
      url: `/client?${query}`,
    };
    let clientData = await fetchApi(payload).then((res) => {
      return res?.data;
    });
    setIndividualData(clientData.rows);
    let list = clientData.rows.map((d) => {
      let obj = {
        value: d?._id,
        label: d?.code,
      };
      return obj;
    });

    setIndividualArray(list);
  };
  const openIndividualModal = (value) => {
    setIndividualShow(value);
  };
  const individualModalOnClick = () => {
    setSelectedId("");
    setUboChecked(false);
    setDirectorChecked(false);
    setReserveChecked(false);
    setShareholderChecked(false);
    setNomineeChecked(false);
    setFormData({});
    setOpenindividual(true);
  };

  const closeModalOnClick = () => {
    setSelectedId("");
    setUboChecked(false);
    setDirectorChecked(false);
    setReserveChecked(false);
    setShareholderChecked(false);
    setNomineeChecked(false);
    setFormData({});
    setOpenindividual(false);
  };
  const getPagination = (current, pageSize) => {
    let params = { ...queryParams };
    params._start = (current - 1) * pageSize;
    params.enId = id ? id : query.id;
    dispatch(getIndividualDetails(params));
    setQueryParams(params);
  };

  const individualEditModalOnClick = async (id, index) => {
    // setSelectedIndex(index + 1);
    setSelectedIndex(individualDetails.data.totalCount - index);
    setSelectedId(id);

    const details = individualDetails.data.rows.find((e) => e._id === id);
    if (!details) return;

    const { shareholders, ubo, nominee, directors, reserve } =
      details.checkedList;

    const params = {
      isAll: true,
      masterType: "individual",
      code: details.individualCode,
    };
    const query = convertQueryParams(params);
    const payload = {
      method: "GET",
      url: `/client?${query}`,
    };

    try {
      const clientData = await fetchApi(payload).then(
        (res) => res?.data?.rows || []
      );

      setIndividualData(clientData);
      setIndividualArray(
        clientData.map((d) => ({ value: d?._id, label: d?.code }))
      );

      const finded = clientData.find((e) => e._id === details.individualId);
      if (finded) {
        const newItem = {
          ...finded,
          pep: finded.details.pep,
          sanction: finded.details.sanction,
          pepText: finded.details.pepText,
          sanctionText: finded.details.sanctionText,
          riskRating: finded.details.riskRating,
          address: { ...finded.details.address },
        };
        setFormData(newItem);
      }

      setUboChecked(ubo);
      setDirectorChecked(directors);
      setReserveChecked(reserve);
      setShareholderChecked(shareholders);
      setNomineeChecked(nominee);
      setOpenindividual(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {" "}
      <div className="d-flex py-4 gap-3 align-items-center">
        <Switch
          className="custom-switch"
          onChange={(value) => openIndividualModal(value)}
        />
        <h4 className="primary-title">Individual</h4>
      </div>
      {individualShow ? (
        <div key={index} id={`individual${index}`}>
          <button
            className="primary-btn ms-auto mb-3"
            onClick={individualModalOnClick}
          >
            <i className="fal fa-plus-circle"></i>Add New
          </button>
          <div className="border-box">
            <Table
              className="custom-table-light mt-md-1 mb-3"
              columns={individualcolumns}
              dataSource={individualDetails?.data?.rows}
              //  pagination={false}
              scroll={{ x: 1200 }}
              pagination={{
                className: "custom-pagination",
                defaultCurrent: 1,
                pageSize: queryParams?._limit,
                total: individualDetails?.data?.totalCount,
                onChange: getPagination,
              }}
            />
            <Modal
              title={selectedId ? "Edit Individual " : "Add New Individual"}
              okText={selectedId ? "Update" : "Save"}
              className="custom-modal"
              open={openindividual}
              centered={true}
              width={1000}
              onOk={saveOnClick}
              onCancel={closeModalOnClick}
              // footer={false}
            >
              <div className="d-flex gap-3 mb-3 align-items-center">
                <h3 className="sub-title">
                  {!selectedId
                    ? ` Individual 0${individualDetails?.data?.count + 1}`
                    : `Individual 0${selectedIndex}`}{" "}
                  &nbsp;
                  <i className="fal fa-question-circle"></i>
                </h3>
                <Checkbox
                  className="custom-checkbox"
                  checked={directorChecked && !reserveChecked}
                  onChange={(e) =>
                    addNewRow(directorFormSchema, e.target.checked, "directors")
                  }
                >
                  Directors
                </Checkbox>
                <Checkbox
                  className="custom-checkbox"
                  checked={shareholderChecked && !nomineeChecked}
                  onChange={(e) =>
                    addNewCustom(
                      shareholderFormSchema,
                      "shareholders",
                      e.target.checked
                    )
                  }
                >
                  Shareholders
                </Checkbox>
                <Checkbox
                  className="custom-checkbox"
                  onChange={(e) =>
                    addNewChange(uboFormSchema, e.target.checked, "ubo")
                  }
                  checked={uboChecked}
                >
                  UBOs
                </Checkbox>
                <Checkbox
                  className="custom-checkbox"
                  checked={nomineeChecked && !shareholderChecked}
                  onChange={(e) =>
                    addNewCustom(
                      nomineeShareholder,
                      "nominee",
                      e.target.checked
                    )
                  }
                >
                  Nominee Shareholder
                </Checkbox>
                <Checkbox
                  className="custom-checkbox"
                  checked={reserveChecked && !directorChecked}
                  onChange={(e) =>
                    addNewRow(directorFormSchema, e.target.checked, "reserve")
                  }
                >
                  Reserve Director
                </Checkbox>
              </div>
              <FormZ
                ref={(el) => (individualFormRef.current[index] = el)}
                formSchema={individualFormSchema}
                formData={formData}
                formOptions={{ code: individualArray }}
                onChange={individualOnChange}
                onKeyUp={(e) => individualDropDownOnClick(e.target.value)}
                formClass="row col-md-10 custom-form mb-3 gy-3 gx-4"
                childClass="col-md-4 mb-0"
                labelClass="mb-1"
                inputClass="w-100"
              />
            </Modal>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
