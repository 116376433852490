import { useState, useEffect, useRef } from "react";
import { Table, Switch, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";

import FormZ from "../../../components/FormZ";
import { getRegisterOfCharges } from "../../../store/registerOfCharges/registerOfChargesSlice";
import {
  sendNotify,
  getOptions,
  fetchApi,
  convertQueryParams,
  getQuery,
} from "../../../helper";

export default function RegisterOfCharges(props) {
  const dispatch = useDispatch();
  const formRef = useRef();

  const registerOfCharges = useSelector((state) => state.registerOfCharges);

  const [openRegisterOfCharges, setOpenRegisterOfCharges] = useState(false);
  const [registerOfChargesShow, setRegisterOfChargesShow] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [queryParams, setQueryParams] = useState({ _start: 0, _limit: 5 });
  const [formData, setFormData] = useState({});

  const [selectedCatId, setSelectedCatId] = useState("");
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  useEffect(() => {
    if (props.selectedId) {
      let params = { ...queryParams };
      params.entityId = props.selectedId;
      dispatch(getRegisterOfCharges(params));
    }
  }, [props.selectedId]);
  useEffect(() => {
    handleRefresh();
  }, [registerOfCharges]);

  const handleRefresh = async () => {
    if (registerOfCharges?.data?.rows.length) {
      setData(registerOfCharges.data.rows);
      setTotalCount(registerOfCharges.data.totalCount);
    } else {
      setData([]);
      setTotalCount(0);
    }
  };
  const editModalOnClick = async (id, index) => {
    const details = data.find((e) => e._id === id);

    setSelectedCatId(id);

    let formData = {
      descriptionOfCharge: details.descriptionOfCharge,
      liabilitySecured: details.liabilitySecured,
      propertyCharged: details.propertyCharged,
      prohibitionsOrRestrictions: details.prohibitionsOrRestrictions,
      dateOfDischarge: details.dateOfDischarge,
      holderOfCharge: details.holderOfCharge,
      trusteeOrChargee: details.trusteeOrChargee,
    };
    setFormData(formData);

    setOpenRegisterOfCharges(true);
  };
  const deleteOnClick = async (id, index) => {
    //  updateStatus;
    let obj = {
      ids: [id],
      isDelete: true,
    };
    const payload = {
      method: "PUT",
      url: `/registerOfCharges/updateStatus`,
      data: obj,
    };

    fetchApi(payload)
      .then((res) => {
        let params = { ...queryParams };
        params.entityId = props.selectedId;
        dispatch(getRegisterOfCharges(params));
        sendNotify("success", res?.message);
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };
  const ROfCcolumns = [
    {
      title: "Description Of Charge",
      dataIndex: "descriptionOfCharge",
      sorter: (a, b) =>
        a.descriptionOfCharge.localeCompare(b.descriptionOfCharge),
    },
    {
      title: "Liability Secured",
      dataIndex: "liabilitySecured",
      sorter: (a, b) => a.liabilitySecured.localeCompare(b.liabilitySecured),
    },
    {
      title: "Property Charged",
      dataIndex: "propertyCharged",
      sorter: (a, b) => a.propertyCharged.localeCompare(b.propertyCharged),
    },
    {
      title: "Trustee Or Chargee",
      dataIndex: "trusteeOrChargee",
      sorter: (a, b) => a.trusteeOrChargee.localeCompare(b.trusteeOrChargee),
    },
    {
      title: "Holder Of Charge",
      dataIndex: "holderOfCharge",
      sorter: (a, b) => a.holderOfCharge.localeCompare(b.holderOfCharge),
    },
    {
      title: "Prohibitions Or Restrictions",
      dataIndex: "prohibitionsOrRestrictions",
      sorter: (a, b) =>
        a.prohibitionsOrRestrictions.localeCompare(
          b.prohibitionsOrRestrictions
        ),
    },
    {
      title: "Date Of Discharge",
      dataIndex: "dateOfDischarge",
      sorter: (a, b) =>
        new Date(a.dateOfDischarge) - new Date(b.dateOfDischarge),
    },
    {
      title: "Delete / Update",
      render: (_, { _id }, index) => (
        <div className="d-flex gap-2 align-items-center justify-content-end">
          <button
            className="action-btn delete-btn"
            onClick={() => deleteOnClick(_id, index)}
          >
            <i className="far fa-trash-alt"></i>
          </button>
          <button
            className="action-btn"
            onClick={() => editModalOnClick(_id, index)}
          >
            <i className="far fa-pencil"></i>
          </button>
        </div>
      ),
    },
  ];

  const openRegisterOfChargesModal = (value) => {
    setRegisterOfChargesShow(value);
  };

  const addModalOnClick = () => {
    setOpenRegisterOfCharges(true);
    setFormData({});
    setSelectedId("");
  };

  const getPagination = (current, pageSize) => {
    let params = {
      ...queryParams,
      _start: (current - 1) * pageSize,
      _limit: pageSize,
      entityId: props.selectedId,
    };
    dispatch(getRegisterOfCharges(params));
    setQueryParams(params);
  };

  const closeModalOnClick = () => {
    setOpenRegisterOfCharges(false);
  };

  const formRefOnChange = (data) => {
    setFormData(data);
  };

  const saveOnClick = () => {
    let formSchema = formRef.current.getPayload();
    formSchema.entityId = props.selectedId;
    const payload = {
      method: selectedCatId ? "PUT" : "POST",
      url: selectedCatId
        ? `/registerOfCharges/update/${selectedCatId}`
        : `/registerOfCharges`,
      data: formSchema,
    };

    fetchApi(payload)
      .then((res) => {
        let params = { ...queryParams };
        params.entityId = props.selectedId;
        dispatch(getRegisterOfCharges(params));
        setOpenRegisterOfCharges(false);
        sendNotify("success", res?.message);
      })
      .catch((error) => {
        sendNotify("error", error?.message);
      });
  };

  const formSchema = [
    {
      name: "descriptionOfCharge",
      value: "",
      type: "text",
      label: "Description",
      placeholder: "Enter here...",
      customClass: "col-md-6",
    },
    {
      name: "liabilitySecured",
      value: "",
      type: "text",
      label: "Liability Secured",
      placeholder: "Enter here...",
      customClass: "col-md-6",
    },
    {
      name: "propertyCharged",
      value: "",
      type: "text",
      label: "Property Charged",
      placeholder: "Enter here...",
      customClass: "col-md-6",
    },
    {
      name: "prohibitionsOrRestrictions",
      value: "",
      type: "text",
      label: "Prohibitions Or Restrictions Charged",
      placeholder: "Enter here...",
      customClass: "col-md-6",
    },
    {
      name: "dateOfDischarge",
      value: "",
      type: "date",
      label: "Date Of Discharge",
      placeholder: "Enter discharge Date",
      customClass: "col-md-6",
    },
    {
      name: "holderOfCharge",
      value: "",
      type: "textarea",
      label: "Holder Of Charge",
      placeholder: "Enter here...",
      customClass: "col-md-10",
    },
    {
      name: "trusteeOrChargee",
      value: "",
      type: "textarea",
      label: "Trustee Or Chargee",
      placeholder: "address...",
      customClass: "col-md-10",
    },
  ];

  return (
    <>
      <div className="d-flex py-4 gap-3 align-items-center">
        <Switch
          className="custom-switch"
          onChange={(value) => openRegisterOfChargesModal(value)}
        />
        <h4 className="primary-title">Register Of Charges</h4>
      </div>
      {registerOfChargesShow ? (
        <div>
          <button
            className="primary-btn ms-auto mb-3"
            onClick={addModalOnClick}
          >
            <i className="fal fa-plus-circle"></i>Add New
          </button>
          <div className="border-box">
            <Table
              className="custom-table-light mt-md-1 mb-3"
              columns={ROfCcolumns}
              dataSource={data}
              scroll={{ x: 1200 }}
              pagination={{
                className: "custom-pagination",
                defaultCurrent: 1,
                pageSize: queryParams._limit,
                total: totalCount,
                onChange: getPagination,
              }}
            />
            <Modal
              title={
                selectedId
                  ? "Edit Register  Of Charges"
                  : "Add Register  Of Charges"
              }
              okText={selectedId ? "Update" : "Save"}
              className="custom-modal"
              open={openRegisterOfCharges}
              centered={true}
              width={1000}
              onOk={saveOnClick}
              onCancel={closeModalOnClick}
            >
              <FormZ
                ref={formRef}
                formSchema={formSchema}
                formData={formData}
                onChange={formRefOnChange}
                formClass="row col-md-10 custom-form mb-3 gy-3 gx-4"
                childClass="col-md-4 mb-0"
                labelClass="mb-1"
                inputClass="w-100"
              />
              {/* <label className="me-3 mb-2"> Trustee Or Chargee</label>
              <FormZ
                formSchema={trusteeOrChargee}
                formData={formData1}
                ref={adsFormRef}
                onChange={adsFormRefOnChange}
                formClass="custom-form row mb-md-4 gy-3 align-items-end"
                labelClass="mb-1"
                inputClass="w-100"
              />
              <label className="me-3 mb-2"> Holder Of Charge</label>
              <FormZ
                formSchema={holderOfCharge}
                ref={ads2FormRef}
                formData={formData2}
                onChange={ads2FormRefOnChange}
                formClass="custom-form row mb-md-4 gy-3 align-items-end"
                // childClass="col-md-12 mb-0"
                labelClass="mb-1"
                inputClass="w-100"
              /> */}
            </Modal>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
