import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import socketIOClient from "socket.io-client";

import FormZ from "../../../components/FormZ";

import {
  sendNotify,
  getOptions,
  fetchApi,
  convertQueryParams,
  getQuery,
} from "../../../helper";

const socket = socketIOClient(
  process.env.REACT_APP_API_URL.split("/api", 1)[0]
);

export default function ParentClient({
  parentClicked,
  setParentClicked,
  setselectedPage,
  selectedDate,
}) {
  let selectedObj = selectedDate ? selectedDate : {};

  const { data, isLoading } = useSelector((state) => state.country);
  const [formData, setFormData] = useState({});
  const [feeCategoryArray, setFeeCategoryArray] = useState([]);
  const [feeSubCategoryArray, setFeeSubCategoryArray] = useState([]);
  useEffect(() => {
    if (!selectedDate) {
      updateMastercode();
      configureSocket();
      handleRefresh();
    }
  }, []);
  useEffect(() => {
    selectedDateOnSet();
  }, [selectedObj]);

  const selectedDateOnSet = async () => {
    if (selectedObj._id) {
      setFormData(selectedObj);
    }
  };
  const configureSocket = () => {
    // socket.on("connection", () => {
    //   console.log(" Connected backend");
    // });
    socket.on("counter", (counter) => {
      if (counter.update === true) {
        updateMastercode();
      }
    });
    return () => {
      disconnectSocket();
    };
  };
  const disconnectSocket = () => {
    socket.off("counter");
  };
  const handleRefresh = async () => {
    let payload = {
      method: "GET",
      url: `/feeCategory?_start=0&_limit=1000`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res.data.rows;
    });
    setFeeCategoryArray(data);
  };
  const updateMastercode = async () => {
    let payload = {
      method: "GET",
      url: `/admin/masterCode?name=parent-client`,
    };
    let data = await fetchApi(payload).then((res) => {
      return res.data.data;
    });
    let originalString = data.seq;
    let numericPart = originalString.match(/\d+$/)[0];
    let incrementedNumeric = parseInt(numericPart, 10) + 1;
    let newNumericPart = String(incrementedNumeric).padStart(
      numericPart.length,
      "0"
    );
    let newString = originalString.replace(/\d+$/, newNumericPart);

    setFormData({ code: newString, status: "prospect" });
  };
  let list = feeCategoryArray.map((d) => {
    let obj = {
      value: d?._id,
      label: d?.name,
    };
    return obj;
  });
  const formRef = useRef();

  let formSchema = [
    {
      name: "code",
      value: "",
      type: "text",
      label: "MasterFile Code",
      placeholder: "Enter masterFile code",
      disabled: true,
    },
    {
      name: "name",
      value: "",
      type: "text",
      label: "MasterFile Name",
      placeholder: "Enter masterFile name",
      required: true,
    },
    {
      name: "type",
      value: "",
      type: "select",
      label: "Parent Client Type",
      placeholder: "Enter parent client type",
      options: [
        {
          value: "introducer",
          label: "Introducer Client",
        },
        {
          value: "endUser",
          label: "End User Client",
        },
      ],
      required: true,
    },
    {
      name: "status",
      value: "",
      type: "select",
      label: "Parent Client Status",
      placeholder: "Select Parent status",
      options: [
        {
          value: "active",
          label: "Active",
        },
        {
          value: "inactive",
          label: "Inactive",
        },
        {
          value: "closing",
          label: "Closing",
        },
        {
          value: "closed",
          label: "Closed",
        },
        {
          value: "transferringOut",
          label: "Transferring Out",
        },
        {
          value: "prospect",
          label: "Prospect",
        },
        {
          value: "transferringIn",
          label: "Transferring In",
        },
        {
          value: "liquidated",
          label: "Liquidated",
        },
        {
          value: "struckOff",
          label: "Struck Off",
        },
        {
          value: "resigned",
          label: "Resigned",
        },
        {
          value: "continuationOut",
          label: "Continuation Out",
        },
        {
          value: "continuationIn",
          label: "Continuation In",
        },
      ],
      disabled: selectedDate ? false : true,
    },
    {
      name: "relationshipManager",
      value: "",
      type: "text",
      label: "Relationship Manager",
      placeholder: "Enter relationship manager name",
    },
    {
      name: "administrator",
      value: "",
      type: "text",
      label: "Administrator",
      placeholder: "Enter administrator name",
    },
    {
      name: "adminManager",
      value: "",
      type: "text",
      label: "Admin Manager",
      placeholder: "Enter admin manager name",
    },
    {
      name: "jurisdiction",
      value: "",
      type: "select",
      label: "Jurisdiction",
      placeholder: "Select jurisdiction",
      options: getOptions(data),
      loading: isLoading,
      showSearch: true,
      required: true,
    },
    {
      name: "feeCat",
      value: "",
      type: "select",
      label: "Fee Schedule Name",
      placeholder: "Select Fee Schedule Name",
      options: getOptions(list),
      loading: isLoading,
      disabled: true,
    },
    {
      name: "feeType",
      value: "",
      type: "select",
      label: "Fee Type",
      placeholder: "Select fee type",
      options: [
        {
          value: "Pre-Paid",
          label: "Pre-paid",
        },
        {
          value: "Post-Paid",
          label: "Post-paid",
        },
      ],
      disabled: true,
    },
    {
      name: "subCat",
      value: "",
      type: "select",
      label: "Fee Sub Category",
      placeholder: "Select fee sub-category",
      mode: "multiple",
      disabled: true,
      inputClass: "ptb-1",
      allowClear: true,
    },
    // {
    //   name: "name",
    //   value: "",
    //   type: "text",
    //   label: "Name",
    //   placeholder: "Enter name",
    // },
    {
      name: "email",
      value: "",
      type: "email",
      label: "Email",
      placeholder: "Enter email address",
      required: true,
    },
    {
      name: "contactNo",
      value: "",
      type: "number",
      label: "Contact Number",
      placeholder: "Enter contact number",
      customClass: "hide-arrows",
      required: true,
    },
    {
      name: "createdAt",
      value: "",
      type: "date",
      label: "Date of Creation",
      placeholder: "Select date",
    },
    // {
    //   name: "relatedClient",
    //   value: "",
    //   type: "text",
    //   label: "Related Client",
    //   placeholder: "Enter related client name",
    // },
  ];
  useEffect(() => {
    console.log(parentClicked, "SDfghj");
    if (parentClicked) {
      onSubmit(selectedDate?._id);
    }
  }, [parentClicked]);

  // const onSubmit = () => {
  //   if (formRef.current.validForm()) {
  //     let options = formRef.current.getPayload();

  //     let obj = {
  //       options: Object.values(options),
  //     };
  //     options.masterType = 'parent-client';
  //     //IC00001, EUC00001, PC00001, CL00001, EN00001, INV00001, COR00001
  //     options.nic = "PC";
  //     options.pid = null;
  //     let payload = {
  //       method: "POST",
  //       url: `/client`,
  //       data: options,
  //     };
  //     let url = `/app/master-search?action=parent-client`

  //     fetchApi(payload)
  //       .then((res) => {
  //         sendNotify("success", res?.message);
  //         setParentClicked(false)
  //         // setTimeout(() => {
  //         //   window.location.href = url;
  //         // }, 1000);

  //       })
  //       .catch((error) => {
  //
  //         sendNotify("error", error?.message);
  //         setParentClicked(false)
  //       });
  //   } else {
  //     sendNotify("error", "Please enter at least one name");
  //   }
  // };

  const onSubmit = (selectedCatId) => {
    const valid = formRef.current.validForm();
    if (!valid) {
      sendNotify("error", "Some fields are missing!");
      return;
    }

    const options = formRef.current.getPayload();
    options.masterType = "parent-client";
    options.nic = "PC";
    const payload = {
      method: selectedCatId ? "PUT" : "POST",
      url: selectedCatId ? `/client/update/${selectedCatId}` : `/client`,
      data: options,
    };

    fetchApi(payload)
      .then((res) => {
        sendNotify("success", res?.message);
        setParentClicked(false);
        if (!selectedCatId) {
          setTimeout(() => {
            //  window.location.href = `/app/master-search?action=parent-client`;
          }, 1000);
        }
      })
      .catch((error) => {
        sendNotify("error", error?.message);
        setParentClicked(false);
      });
  };

  setselectedPage("parent-client");

  const handleOnChange = async (value) => {
    let data = { ...selectedObj, ...value };
    //setFormData(data);
    let { feeCat, feeType } = value;
    if (feeCat && feeType) {
      let params = { catId: feeCat, type: feeType };
      let query = convertQueryParams(params);
      let payload = {
        method: "GET",
        url: `/feeCategory/sub/?${query}`,
      };
      let data = await fetchApi(payload).then((res) => {
        return res.data.data;
      });

      let sublist = data
        ? data?.map((d) => {
            let obj = {
              value: d?._id,
              label: d?.catType,
            };
            return obj;
          })
        : [];
      setFeeSubCategoryArray(sublist);
    }
    setFormData(data);
  };
  return (
    <div className="custom-form">
      <h2 className="sub-title mb-4">
        {`Master File ${!selectedDate ? "Creation" : "Update"}: Parent Client`}
      </h2>
      <FormZ
        formSchema={formSchema}
        ref={formRef}
        formData={formData}
        onChange={handleOnChange}
        formClass="row col-md-9 mb-3 mb-md-4 gy-3 gx-4"
        formOptions={{ subCat: feeSubCategoryArray }}
        childClass="col-md-4 mb-0"
        labelClass="mb-1"
        inputClass="w-100"
      />
      {/* <div className="d-flex gap-3">
                <button className='primary-btn'>Analyze</button>
                <button className='outline-btn'>Web Search</button>
            </div> */}
    </div>
  );
}
