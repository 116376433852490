import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import { Modal } from "antd";
import FormZ from "../../components/FormZ";
import { sendNotify, fetchApi } from "../../helper";
import { checkLogged } from "../../store/auth/authSlice";
const { confirm, error } = Modal;

const generateRandomCaptcha = () => {
  const chars = "abcdefghijklmnopqrstuvwxyz0123456789";
  let captchaText = "";
  for (let i = 0; i < 6; i++) {
    captchaText += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return captchaText;
};
const fontFamilies = [
  "Courier, monospace",
  "Arial, sans-serif",
  "Verdana, sans-serif",
  "Georgia, serif",
  "Times New Roman, serif",
  "Comic Sans MS, cursive",
  "Impact, sans-serif",
  "Lucida Console, monospace",
];

export default function Login() {
  const dispatch = useDispatch();
  const formRef = useRef();

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState("");
  const [captchaModalOpen, setCaptchaModalOpen] = useState(false);
  const [twoFactorCode, setTwoFactorCode] = useState("");
  const [userID, setUserID] = useState("");
  const [loginToken, setLoginToken] = useState(null);

  const [captchaText, setCaptchaText] = useState(generateRandomCaptcha());
  const [inputText, setInputText] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    let captcha = localStorage.getItem("captcha");
    if (captcha) {
      setCaptchaModalOpen(captcha);
    }
  }, []);

  const reloadCaptcha = () => {
    setCaptchaText(generateRandomCaptcha());
    setInputText("");
    setIsVerified(false);
  };

  const handleCaptchaVerification = () => {
    if (inputText.toLowerCase() === captchaText.toLowerCase()) {
      setIsVerified(true);

      setCaptchaModalOpen(false);
      localStorage.removeItem("captcha");
      setInputText("");
      sendNotify("success", "CAPTCHA Verified Successfully!");
    } else {
      setIsVerified(false);
      sendNotify("error", "Incorrect CAPTCHA, please try again.");
      reloadCaptcha();
    }
  };

  let formSchema = [
    {
      name: "email",
      value: "",
      type: "email",
      label: "Email",
      placeholder: "Enter email",
      required: true,
    },
    {
      name: "password",
      value: "",
      type: "password",
      label: "Password",
      placeholder: "Enter password",
      required: true,
    },
  ];

  const login = () => {
    let valid = formRef.current.validForm();
    if (valid) {
      setLoading(true);
      let obj = formRef.current.getPayload();
      let payload = {
        method: "post",
        url: "/admin/login",
        data: obj,
      };
      fetchApi(payload)
        .then((response) => {
          if (response) {
            setLoading(false);
            if (response.error) {
              localStorage.setItem("captcha", true);
              setCaptchaModalOpen(true);
              sendNotify("error", response.error?.response?.data?.message);
            } else {
              if (!response?.data?.f2aEnable) {
                sendNotify("success", "Login successfully");
                let token = JSON.stringify(response?.data?.accessToken);
                const { companyName, slug, theme } =
                  response?.data?.otherDetails;
                console.log(companyName, slug, theme, "asdfghjkl;");
                localStorage.setItem("theme-color", theme);
                localStorage.setItem("companyName", companyName);
                localStorage.setItem("companySlug", slug);
                localStorage.setItem(
                  process.env.REACT_APP_JWT_TOKEN,
                  token?.replace(/\"/g, "")
                );
                dispatch(checkLogged());
                formRef.current.clearForm();
              } else {
                setUserID(response.data._id);
                setLoginToken(response.data);
                setIsModalOpen(true);
              }
            }
          }
        })
        .catch((error) => ({ error: JSON.stringify(error) }));
    } else {
      sendNotify("error", "Some fields are missing!");
    }
  };
  const twoFactor = () => {
    let payload = {
      method: "post",
      url: `/auth/verify-token`,
      data: {
        token: twoFactorCode,
        id: userID,
      },
    };
    fetchApi(payload)
      .then((response) => {
        if (response) {
          setLoading(false);
          if (response.error) {
            sendNotify("error", response.error?.response?.data?.message);
          } else {
            showSuccessModal();
            authVerified();
          }
        }
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  };

  const showSuccessModal = () => {
    Modal.success({
      title: "Login Successful",
      content:
        "Your Two-Factor Authentication code has been verified, and you are now logged in successfully.",
      open: true,
      footer: false,
    });
    setTimeout(() => {
      Modal.destroyAll();
    }, 2000);
  };

  const authVerified = () => {
    const { companyName, slug, theme } = loginToken.otherDetails;
    let token = JSON.stringify(loginToken.accessToken);
    const validSlug = slug.replace(/[^a-zA-Z0-9_-]/g, "");

    localStorage.setItem("theme-color", theme);
    localStorage.setItem("companyName", companyName);
    localStorage.setItem("companySlug", encodeURIComponent(validSlug));
    localStorage.setItem(
      process.env.REACT_APP_JWT_TOKEN,
      token?.replace(/\"/g, "")
    );
    dispatch(checkLogged());
    formRef.current.clearForm();
  };
  const sendEmail = () => {
    let obj = formRef.current.getPayload();
    let payload = {
      method: "post",
      url: "/admin/reset-request",
      data: obj,
    };
    fetchApi(payload).then((response) => {
      if (response.error) {
        sendNotify("error", response.error?.response?.data?.message);
      } else {
        sendNotify("success", response.message);
      }
    });
  };
  const confirmDelete = () => {
    confirm({
      icon: (
        <div className="modal-icon">
          <i className="fas fa-envelope color-primary"></i>
        </div>
      ),
      title: <h5>Password Reset Requested</h5>,
      content: (
        <p>
          We've notified our team to reset your password. Soon, you'll receive
          an email with a link to set up a new password.
        </p>
      ),
      className: "custom-confirm",
      centered: true,
      okText: "Reset Password",
      closable: true,
      onOk() {
        sendEmail();
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };
  return (
    <div className="auth-form">
      <FormZ formSchema={formSchema} ref={formRef} onKeyPress={login} />
      <span className="auth-link" onClick={confirmDelete}>
        Forgot Password?
      </span>
      {/*  */}
      <button
        className={`primary-btn auth-btn ${loading ? "btn-loading" : ""}`}
        onClick={login}
      >
        {loading ? <i className="far fa-spinner-third fa-spin"></i> : ""}
        Sign in
      </button>
      {/* <p className="auth-footer">
        Don’t have an account? <Link to={"/register"}>Sign up</Link>
      </p> */}
      <Modal
        title={
          <h5>
            {" "}
            <span className="custom-icon">
              <i className="far fa-lock-alt"></i>
            </span>{" "}
            Two Factor Authentication
          </h5>
        }
        open={isModalOpen}
        centered
        className="custom-modal two-factor-modal"
        onOk={twoFactor}
        width={400}
        okText={"Verify"}
        onCancel={() => setIsModalOpen(false)}
        cancelButtonProps={{ style: { display: "none" } }}
        closable={false}
      >
        <div className="custom-modal-content">
          <p>Enter the otp, which is on the two-factor app.</p>

          <OtpInput
            value={twoFactorCode}
            onChange={setTwoFactorCode}
            numInputs={6}
            containerStyle="auth-otp-wrapper"
            renderInput={(props) => (
              <input {...props} placeholder="0" type="number" />
            )}
          />
        </div>
      </Modal>
      <Modal
        title={
          <h5>
            {" "}
            <span className="custom-icon">
              <i class="fas fa-user-check"></i>
            </span>{" "}
            CAPTCHA
          </h5>
        }
        open={captchaModalOpen}
        centered
        className="custom-modal two-factor-modal"
        onOk={handleCaptchaVerification}
        width={400}
        okText={"Verify"}
        onCancel={() => setCaptchaModalOpen(false)}
        cancelButtonProps={{ style: { display: "none" } }}
        closable={false}
        maskClosable={false}
      >
        <div>
          <div style={{ marginTop: "20px" }}>
            <div
              style={{
                backgroundColor: "#f0f0f0",
                display: "inline-block",
                padding: "10px",
                fontSize: "24px",
                letterSpacing: "2px",
                display: "flex",
                justifyContent: "center",
                transform: "rotate(-5deg)",
              }}
            >
              {captchaText.split("").map((char, index) => (
                <span
                  key={index}
                  style={{
                    fontFamily: fontFamilies[index % fontFamilies.length], // Use a different font for each character
                    margin: "0 5px",
                  }}
                >
                  {char}
                </span>
              ))}
            </div>
            <button className="btn btn-light me-2 mt-2" onClick={reloadCaptcha}>
              <i class="fas fa-sync-alt"></i>
            </button>
          </div>
          <div className="custom-form  mt-2 mb-2 mb-md-3">
            <div className="row align-items-end">
              <label>Type the above word:</label>
              <input
                type="text"
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                style={{ marginLeft: "10px" }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
